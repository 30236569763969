import React, { useState, useEffect } from "react";
import "../../css/SearchForm.css";
import GuestInput from "./GuestInput";
import { serverUrl } from "../Controllers/Config";
import { countriesData } from "../Controllers/CountriesData";
import Select from "react-select";

const SearchForm = ({
  defaultLocation,
  onSearch,
  onSearchOptions,
  onError,
  onSearching,
  hotelLocations, 
}) => {
  const [location, setLocation] = useState(defaultLocation || "");
  const [checkInDate, setCheckInDate] = useState(getToday());
  const [checkOutDate, setCheckOutDate] = useState(getToday());
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [nationality, setNationality] = useState(
    countriesData.find((country) => country.value === "KE")
  );
  const [searching, setSearching] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [roomData, setRoomData] = useState([
    { adults: 1, children: 0, childrenAges: [] },
  ]);

  // Use hotelLocations to create unique and sorted city options
  const uniqueCities = Array.from(new Set(hotelLocations));
  const sortedCities = uniqueCities.sort();


  // Map sortedCities to create options for Select component
  const cityOptions = [
    { label: "Select a location", value: "" },
    ...sortedCities.map((city) => ({ label: city, value: city })),
  ];

  useEffect(() => {
    // If the default location exists and matches a city, set it
    if (defaultLocation && sortedCities.includes(defaultLocation)) {
      setLocation(defaultLocation);
    }
  }, [defaultLocation, sortedCities]);

  // Trigger search when location is set
  useEffect(() => {
    if (defaultLocation && location) {
      handleSearch();
    }
  }, [location]);

  const handleLocationChange = (selectedOption) => {
    setLocation(selectedOption.value);
    setAlertMessage(null);
  };

  const handleCheckInDateChange = (e) => {
    const newCheckInDate = e.target.value;
    setCheckInDate(newCheckInDate);
    setAlertMessage(null);
    if (newCheckInDate > checkOutDate) {
      setCheckOutDate(newCheckInDate);
    }
  };

  const handleCheckOutDateChange = (e) => {
    const newCheckOutDate = e.target.value;
    setAlertMessage(null);
    if (newCheckOutDate >= checkInDate) {
      setCheckOutDate(newCheckOutDate);
    }
  };

  function getToday() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleNationalityChange = (selectedNationality) => {
    setAlertMessage(null);
    setNationality(selectedNationality);
  };

  const handleAdultsChange = (newAdults) => {
    setAlertMessage(null);
    setAdults(newAdults);
  };

  const handleChildrenChange = (newChildren) => {
    setAlertMessage(null);
    setChildren(newChildren);
  };

  const handleRoomsChange = (newRooms) => {
    setAlertMessage(null);
    setRooms(newRooms);
  };

  const handleSearch = async () => {
    console.log("roomData", roomData);
    if (!location) {
      setAlertMessage("Please enter a location before searching.");
      return;
    }
    if (!checkInDate) {
      setAlertMessage("Please select a check-in date before searching.");
      return;
    }
    if (!checkOutDate) {
      setAlertMessage("Please select a check-out date before searching.");
      return;
    }
    if (!nationality) {
      setAlertMessage("Please select your nationality before searching.");
      return;
    }

    setSearching(true);
    onSearching(true);
    const checkIn = checkInDate;
    const nights =
      checkInDate === checkOutDate
        ? 1
        : calculateNights(checkInDate, checkOutDate);
    const totalGuests = roomData
      .map((room) => {
        if (room.children > 0) {
          return `${room.adults}-${room.childrenAges.join(",")}`;
        } else {
          return `${room.adults}`;
        }
      })
      .join(".");
    const customerNationality = nationality ? nationality.value : "";
    const hotelIds = location;

    const requestData = {
      checkIn,
      nights,
      guests: totalGuests,
      customerNationality,
      hotelIds,
    };

    try {
      const response = await fetch(`${serverUrl}/hyperguest/searchProperty`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const data = await response.json();
        onSearchOptions(requestData);
        onSearch(data);
        console.log("Search results:", data);
      } else {
        const data = await response.json();
        onError(data);
        console.error("Error searching for hotels", data);
      }
    } catch (error) {
      onError(error);
      console.error("Error in the search request:", error);
    } finally {
      setSearching(false);
      onSearching(false);
    }
  };

  const calculateNights = (checkInDate, checkOutDate) => {
    const checkInDateObj = new Date(checkInDate);
    const checkOutDateObj = new Date(checkOutDate);
    const timeDiff = checkOutDateObj - checkInDateObj;
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  };

  return (
    <div className="search-form">
      <form>
        {alertMessage && (
          <div
            className="alert alert-warning alert-dismissible fade show"
            role="alert"
          >
            {alertMessage}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        )}
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="location">Location</label>
              <Select
                options={cityOptions}
                value={{
                  label: location || "Select a location",
                  value: location || "",
                }}
                onChange={handleLocationChange}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="checkin">Check-in</label>
              <input
                type="date"
                id="checkin"
                name="checkin"
                className="form-control"
                value={checkInDate}
                min={getToday()}
                onChange={handleCheckInDateChange}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="checkout">Check-out</label>
              <input
                type="date"
                id="checkout"
                name="checkout"
                className="form-control"
                value={checkOutDate}
                min={checkInDate}
                onChange={handleCheckOutDateChange}
              />
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="form-group">
              <label htmlFor="guests">Guests</label>
              <GuestInput
                adults={adults}
                children={children}
                nationality={nationality}
                rooms={rooms}
                onAdultsChange={handleAdultsChange}
                onChildrenChange={handleChildrenChange}
                onNationalityChange={handleNationalityChange}
                onRoomsChange={handleRoomsChange}
                roomData={roomData}
                setRoomData={setRoomData}
              />
            </div>
          </div>
        </div>
        <div className="row mx-auto text-center">
          <button
            type="button"
            className="btn-1c search-button"
            onClick={handleSearch}
            disabled={searching}
          >
            {searching ? "Searching..." : "Search"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchForm;
